$desktop-width : 1170px;
$min-desktop-width: 1024px;
$medium-width : 920px;
$tablet-width : 720px;
$desktop-max-width : 1170px;
$phone-large-width : 415px;
$header-max-height : 66px;
$submenu-height : 56px;
$verification-banner-height : 48px;
$verification-banner-mobile-height : 60px;
$space-large:32px;
$space-normal:20px;
$space-small:16px;
$space-tiny:12px;
$space-ten-pixel:10px;
$space-eight-pixel:8px;