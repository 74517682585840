//Only for bootstrap
$grid-columns: 12;
$grid-gutter-width: 22px;
$grid-float-breakpoint: 768px;
$screen-sm-min: 768px;
$screen-sm-max: 767px;
$screen-md-min: 992px;
$screen-md-max: 993px;
$screen-lg-min: 1200px;
$screen-lg-max: 1199px;
$screen-xs-min: 576px;
$screen-xs-max: 575px;
$screen-sm-max: 767px;

$header-font-size: 20px;
$button-forn-size: 14px;
$sub-header-font-size: 16px;
$medium-font-size: 14px;
$small-font-size:12px;
$tiny-font-size:10px;
$table-font-size:14px;
$large-icon-size: 72px;
$small-icon-size: 20px;

@mixin white-card-shadow() {
    -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow:    0 2px 3px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
}

@mixin box-hover-in() {
  box-shadow: 0 4px 10px 0 rgba(0,0,0,.3);
  transition: box-shadow 0.3s ease-in-out;
}

@mixin box-hover-out() {
  box-shadow: 0;
  transition: box-shadow 0.3s ease-in-out;
}

@mixin box-shadow-none() {
  -webkit-box-shadow: none;
  -moz-box-shadow:  none;
  box-shadow:none;
}
  
@mixin clearfix() {
    &:before,
    &:after {
      content: " ";
      display: table;
    }
    &:after {
      clear: both;
    }
}

// Creates a wrapper for a series of columns
@mixin make-row($gutter: $grid-gutter-width) {
    // Then clear the floated columns
    @include clearfix();
  
    @media (min-width: $screen-sm-min) {
      margin-left:  ($gutter / -2);
      margin-right: ($gutter / -2);
    }
  
    // Negative margin nested rows out to align the content of columns
    .row {
      margin-left:  ($gutter / -2);
      margin-right: ($gutter / -2);
    }
 }
  
 // Generate the extra small columns
 @mixin make-xs-column($columns, $gutter: $grid-gutter-width) {
   position: relative;
   // Prevent columns from collapsing when empty
   min-height: 1px;
   // Inner gutter via padding
   padding-left:  ($gutter / 2);
   padding-right: ($gutter / 2);
 
   // Calculate width based on number of columns available
   @media (min-width: $grid-float-breakpoint) {
     float: left;
     width: percentage(($columns / $grid-columns));
   }
 }
 
 // Generate the small columns
 @mixin make-sm-column($columns, $gutter: $grid-gutter-width) {
   position: relative;
   // Prevent columns from collapsing when empty
   min-height: 1px;
   // Inner gutter via padding
   padding-left:  ($gutter / 2);
   padding-right: ($gutter / 2);
 
   // Calculate width based on number of columns available
   @media (min-width: $screen-sm-min) {
     float: left;
      width: percentage(($columns / $grid-columns));
   }
 }
 
 // Generate the small column offsets
 @mixin make-sm-column-offset($columns) {
   @media (min-width: $screen-sm-min) {
     margin-left: percentage(($columns / $grid-columns));
   }
 }

 @mixin make-sm-column-push($columns) {
   @media (min-width: $screen-sm-min) {
     left: percentage(($columns / $grid-columns));
   }
 }

 @mixin make-sm-column-pull($columns) {
   @media (min-width: $screen-sm-min) {
     right: percentage(($columns / $grid-columns));
   }
 }
 
 // Generate the medium columns
 @mixin make-md-column($columns, $gutter: $grid-gutter-width) {
   position: relative;
   // Prevent columns from collapsing when empty
   min-height: 1px;
   // Inner gutter via padding
   padding-left:  ($gutter / 2);
   padding-right: ($gutter / 2);
 
   // Calculate width based on number of columns available
   @media (min-width: $screen-md-min) {
     float: left;
     width: percentage(($columns / $grid-columns));
   }
 }
 
 // Generate the medium column offsets
 @mixin make-md-column-offset($columns) {
   @media (min-width: $screen-md-min) {
     margin-left: percentage(($columns / $grid-columns));
   }
 }

 @mixin make-md-column-push($columns) {
   @media (min-width: $screen-md-min) {
     left: percentage(($columns / $grid-columns));
   }
 }

 @mixin make-md-column-pull($columns) {
   @media (min-width: $screen-md-min) {
     right: percentage(($columns / $grid-columns));
   }
 }
 
 // Generate the large columns
 @mixin make-lg-column($columns, $gutter: $grid-gutter-width) {
   position: relative;
   // Prevent columns from collapsing when empty
   min-height: 1px;
   // Inner gutter via padding
   padding-left:  ($gutter / 2);
   padding-right: ($gutter / 2);
 
   // Calculate width based on number of columns available
   @media (min-width: $screen-lg-min) {
     float: left;
     width: percentage(($columns / $grid-columns));
   }
 }
 
 // Generate the large column offsets
 @mixin make-lg-column-offset($columns) {
   @media (min-width: $screen-lg-min) {
     margin-left: percentage(($columns / $grid-columns));
   }
 }

 @mixin make-lg-column-push($columns) {
   @media (min-width: $screen-lg-min) {
     left: percentage(($columns / $grid-columns));
   }
 }

 @mixin make-lg-column-pull($columns) {
   @media (min-width: $screen-lg-min) {
     right: percentage(($columns / $grid-columns));
   }
 }

 @mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

@mixin transition($transition...) {
  -moz-transition:    $transition;
  -o-transition:      $transition;
  -webkit-transition: $transition;
  transition:         $transition;
}
