@import './Mixins.scss';
@import './Colors.scss';
@import './Spacing.scss';

.loading-overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.3); /* Black background with opacity */
    background-image: url('https://cdn6.agoda.net/images/desktop/preloader_v2.gif'), linear-gradient(to right, rgb(75, 128, 243), rgb(140, 105, 202));
    background-repeat: no-repeat;
    background-position:  center center;
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
}

.loading {
    background-image: url('https://cdn6.agoda.net/images/desktop/preloader_v2.gif');
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    width: 100%;
    height: 100%;
    min-height: 150px;
    >*{
        display: none;
    }
}

.loading-2 {
    background-image: url("/images/agoda-loading.gif");
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    width: 100%;
    height: 100%;
    min-height: 100px;
    background-size: 100px;
    >*{
        display: none;
    }
}


.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.white-card, .light-blue-card, .black-card, .grey-card {    
    @include white-card-shadow();
    padding: $space-small;

    .title {
        font-size: $header-font-size;
        color: $primary-blue;
        margin: 0 0 $space-small 0;

        &.title-dark {
            font-size:$header-font-size; 
            color: $primary-grey;
            font-weight: 600;
        }
        &.sub-title-dark {
            font-size: $sub-header-font-size; 
            color: $primary-grey;            
            font-weight: 600;
        }

    }
}

.black-card {
    background-color: $primary-grey;

    > .title {
        color: $white;
    }
}

.white-card {
    background-color:$white;
}

.light-blue-card {
    background-color: $transparent-light-blue;
}

.grey-card {
    background-color: $lighter-grey-1;

    > .title {
        color: $white;
    }
}

.error-message {
    color: $primary-red;
}

.error-icon {
    color: $primary-red;
    font-size: 60px;
    margin-bottom: $space-normal;
}

.display-block {
    display: block;
}
.button {
    padding: $space-eight-pixel $space-small;
    cursor: pointer;
    text-align: center;
    background-color: $lighter-grey-4;
    border: none;
    border-radius: 3px;
    margin-right: $space-small;
    font-weight: 600;
    user-select: none;
    display: inline-block;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &.button-wide {
        padding: $space-eight-pixel $space-large;
    }
    
    &:hover {
        text-decoration: none;
    }
    
    &.button-right {
        margin-left: $space-small;
        margin-right: 0;
        float: right;
    }

    &.button-blue {
        background-color: $primary-blue;
        color: $white;

        &:hover {
            color: $white;
            background-color: $lighter-blue;
        }

        &.disabled {
            background-color: $light-blue;
            cursor: not-allowed;
        }
    }

    &.button-green {
        background-color: $primary-sea-green;
        color: $white;
    }

    &.button-border {
        background: none;
        border: 1px solid $primary-grey;
        color: $primary-grey;
    }

    &.button-white {
        background: $white;
        border: 1px solid $primary-grey;
        color: $primary-grey;
    }

    &.button-border-blue {
        background: none;
        border: 1px solid $primary-blue;
        color: $primary-blue;
    }
}   

.modal-showing {
    overflow-y: hidden;
}

.baloon-tooltip { 
    -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    padding: $space-small;
    color: $black;
    background: $white;
}

.baloon-tooltip:after {
    content: "";
    position: absolute;
    bottom: -15px;/* value = - border-top-width - border-bottom-width */
    left: 50px;/* controls horizontal position */
    border-width: 15px 15px 0;/* vary these values to change the angle of the vertex */
    border-style: solid;
    border-color: $light-grey transparent;
}

.baloon-tooltip.top:after {
    top: -15px;/* value = - border-top-width - border-bottom-width */
    right: 50px;/* controls horizontal position */
    bottom: auto;
    left: auto;
    border-width: 0 15px 15px; /* vary these values to change the angle of the vertex */
    border-color: $light-grey transparent;
}

.rtl{
    direction: rtl;
}

.pc-row {
    @include make-row(0);
}
.md-col-1 {
    @include make-md-column(1);
}

.md-col-2 {
    @include make-md-column(2);
}

.md-col-3 {
    @include make-md-column(3);
}

.md-col-4 {
    @include make-md-column(4);
}

.md-col-5 {
    @include make-md-column(5);
}

.md-col-6 {
    @include make-md-column(6)
}

.md-col-7 {
    @include make-md-column(7);
}

.md-col-8 {
    @include make-md-column(8);
}

.md-col-9 {
    @include make-md-column(9);
}

.md-col-10 {
    @include make-md-column(10);
}

.md-col-11 {
    @include make-md-column(11);
}

.md-col-12 {
    @include make-md-column(12);
}

.sm-col-1 {
    @include make-sm-column(1);
}

.sm-col-2 {
    @include make-sm-column(2);
}

.sm-col-3 {
    @include make-sm-column(3);
}

.sm-col-4 {
    @include make-sm-column(4);
}

.sm-col-5 {
    @include make-sm-column(5);
}

.sm-col-6 {
    @include make-sm-column(6)
}

.sm-col-7 {
    @include make-sm-column(7);
}

.sm-col-8 {
    @include make-sm-column(8);
}

.sm-col-9 {
    @include make-sm-column(9);
}

.sm-col-10 {
    @include make-sm-column(10);
}

.sm-col-11 {
    @include make-sm-column(11);
}

.sm-col-12 {
    @include make-sm-column(12);
}

.lg-col-1 {
    @include make-lg-column(1);
}

.lg-col-2 {
    @include make-lg-column(2);
}

.lg-col-3 {
    @include make-lg-column(3);
}

.lg-col-4 {
    @include make-lg-column(4);
}

.lg-col-5 {
    @include make-lg-column(5);
}

.lg-col-6 {
    @include make-lg-column(6)
}

.lg-col-7 {
    @include make-lg-column(7);
}

.lg-col-8 {
    @include make-lg-column(8);
}

.lg-col-9 {
    @include make-lg-column(9);
}

.lg-col-10 {
    @include make-lg-column(10);
}

.lg-col-11 {
    @include make-lg-column(11);
}

.lg-col-12 {
    @include make-lg-column(12);
}

.xs-col-1 {
    @include make-xs-column(1);
}

.xs-col-2 {
    @include make-xs-column(2);
}

.xs-col-3 {
    @include make-xs-column(3);
}

.xs-col-4 {
    @include make-xs-column(4);
}

.xs-col-5 {
    @include make-xs-column(5);
}

.xs-col-6 {
    @include make-xs-column(6)
}

.xs-col-7 {
    @include make-xs-column(7);
}

.xs-col-8 {
    @include make-xs-column(8);
}

.xs-col-9 {
    @include make-xs-column(9);
}

.xs-col-10 {
    @include make-xs-column(10);
}

.xs-col-11 {
    @include make-xs-column(11);
}

.xs-col-12 {
    @include make-xs-column(12);
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.item-md-left {
    padding-left: 0; 
    @media (max-width: $screen-md-min) {
        padding-right: 0;
        margin-bottom: $space-small;
    }
}

.item-md-right {
    padding-right: 0;
    @media (max-width: $screen-md-min) { 
        padding-left: 0; 
    }
}

.bold {
    font-weight: 700;
}

.flex-md {
    @media (min-width: $screen-md-min) { 
        display: flex;

        .flex-md-1 {
            flex: auto;
        }
    }
}


@keyframes fadeinout {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}