@import "../../Common/Css/Template.scss";

.signin-container {
  height: 666px;
  width: 1000px;
  .signin-section {
  }
  .presentation-section {
    height: 666px;
    background-color: $dark-blue;
    background: url("/images/confetti_header.png"), linear-gradient(to right, rgb(75, 128, 243), rgb(140, 105, 202));
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    vertical-align: middle;
    color: $white;

    .presentation-section-text {
      padding: 10px 20px;
    }
  }
}